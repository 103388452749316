export default function CookieConsent() {
    var cookiehint = {};

    cookiehint.check = function() {
        if ( getCookie( 'cookiesAccepted' ) === null ) {
            // Show cookie banner
            var banner = document.createElement( 'div' );
            banner.id = "cookie-alert";
            banner.innerHTML =
            '<div class="cookie-info-wrapper fixed left-0 right-0 bottom-0 z-50 bg-white flex flex-wrap md:flex-no-wrap justify-between items-center"><!-- z-50 is just there to be sure nothing overlaps the cookie bar -->\n' +
            '    <div class="p-6">\n' +
            '        <p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. <a class="text-green-500 hover:text-green-700 focus:text-green-500 underline" href="/datenschutz/">Mehr Informationen</a></p>\n' +
            '    </div>\n' +
            '    <div class="flex w-full md:w-auto items-center justify-center">\n' +
            '        <button id="cookie-alert-decline" class="w-full hover:text-white focus:text-white hover:bg-green-700 focus:bg-green-700 py-8 px-16 text-center">Ablehnen</button>\n' +
            '        <button id="cookie-alert-accept" class="w-full text-white inline-block bg-green-500 hover:bg-green-700 focus:bg-green-700 py-8 px-16 text-center">Akzeptieren</button>\n' +
            '    </div>\n' +
            '</div>\n';

            banner.addEventListener( 'click', function( e ) {
                if ( e.target ) {
                    var targetButton = e.target;
                    if ( e.target.tagName == 'SPAN' ) {
                        targetButton = e.target.parentNode;
                    }
                    if ( targetButton.id == 'cookie-alert-accept' ) {
                        cookiehint.acceptCookies( true );
                    }
					if ( targetButton.id == 'cookie-alert-decline' ) {
                        cookiehint.acceptCookies( false );
					}
                }
            } );
            document.body.appendChild( banner );
        } else if ( getCookie( 'cookiesAccepted' ) == 1 ) {
            onAccepted();
        }
    }

    cookiehint.acceptCookies = function( accepted ) {
        // Set cookie
        var date = new Date();
        date.setTime( date.getTime() + (365 * 24 * 60 * 60 * 1000) ); // 5 years
        document.cookie = "cookiesAccepted=" + (accepted == 1 ? 1 : 0) + "; expires=" + date.toUTCString() + "; path=/";

        if ( accepted == 1 ) {
            onAccepted();
        }

        // remove cookie banner
        var banner = document.getElementById( "cookie-alert" );
        banner.parentNode.removeChild( banner );
    }

    function getCookie( name ) {
        var nameEQ = name + "=";
        var ca = document.cookie.split( ';' );
        for ( var i = 0; i < ca.length; i++ ) {
            var c = ca[ i ];
            while ( c.charAt( 0 ) == ' ' ) c = c.substring( 1, c.length );
            if ( c.indexOf( nameEQ ) == 0 ) return c.substring( nameEQ.length, c.length );
        }
        return null;
    }

    return cookiehint;
}
function onAccepted(){
    //this part only gets executed if user accepts cookies
    //yes, there currently is no tracking code - however the PMs decided that the cookie-bar should be built anyhow - there might be cookies to track in the future
}
